import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import { userType as userTypeVendor } from "../../../common/Utils/ApiConstants";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  getDayJs,
  getFilterValue,
  getMoment,
} from "../../../common/Utils/commonFunction";
import { moduleTypes } from "../../../common/users/userTypes";
import {
  useExportTaskReportListMutation,
  useGetTaskReportGraphDetailsMutation,
  useGetTaskReportListMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import { taskStatusType } from "../../AddTask/constants";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import { moduleType } from "../../Task/Components/constants";
import DropDownField from "../../TextField/DropdownField";
import Donutchart from "../../common/donutchart";
import Loader from "../../staff/Owner/LoadingComp";
import { isSeriesAllZeros } from "../components/utils";
import { getTaskReportColumns } from "./column.data";
import { priorityOptions, sortOptions } from "./components/constants";
import DashboardDropDown from "../../TextField/DashboardDropdown";
import { allFilterData, HomeScreenStrings } from "../../Dashboard/utils";
import DatePickerModal from "../../common/datePickerModal";

const TaskReports = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const csvLink = useRef();
  const { selectedCompanyList, userData, userType, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [showFilter, setShowFilter] = useState(false);
  const [showStaffFilter, setShowStaffFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [taskListState, setTaskListState] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [selectedSortBy, setSelectedSortBy] = useState("ALL");
  const [selectedPriority, setSelectedPriority] = useState("ALL");
  const [selectedStaff, setSelectedStaff] = useState("SELF");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [graphDetails, setGraphDetails] = useState("");
  const [staffOption, setStaffOption] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [csvData, setCSVData] = useState([]);
  const { state } = useLocation();
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [selectedFilterGraph, setSelectedFilterGraph] = useState(
    HomeScreenStrings.MONTH
  );
  const [value, setValue] = useState(getDayJs(getMoment().format()));
  const [filterSelectedDate, setFilterSelectedDate] = useState();
  const isDashboardFiltersEnabled = userData?.moduleAccess?.find(
    (item) => Object.keys(item)[0] === moduleTypes.DASHBOARDFILTERS
  );
  const hideDatePickerModal = () => {
    setShowDatePickerModal(false);
  };

  useEffect(() => {
    if (userData) {
      const { taskStatus } = userData?.masterData;
      let filter = [];
      const filterData = userData?.moduleAccess?.find(
        (item) => Object.keys(item)[0] === moduleTypes.TaskReport
      );
      Object?.values(filterData)[0]?.map((item) => {
        item?.isEnable &&
          filter.push({
            label: Translate(`taskReport:${item?.label}`),
            value: getFilterValue(item.label),
          });
      });

      const updatedStatus = taskStatus?.map((item) => ({
        label: item,
        value: item,
      }));
      setStatusOptions(updatedStatus);
      setStaffOption(filter);
      setSelectedStaff(filter[0]?.value);
      setShowStaffFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  const [
    getReportTaskListApi,
    {
      data: taskDataList,
      isLoading: isTaskLoading,
      isSuccess: isTaskSuccess,
      isError: isTaskError,
      error: taskError,
    },
  ] = useGetTaskReportListMutation();

  const [
    getGraphDetailsApi,
    {
      data: graphDataList,
      isLoading: isGraphDataLoading,
      isError: isGraphDataError,
      error: graphDataError,
    },
  ] = useGetTaskReportGraphDetailsMutation();

  const [
    exportTaskDetailsApi,
    {
      data: exportTaskData,
      isLoading: isExportTaskLoading,
      error: exportTaskError,
    },
  ] = useExportTaskReportListMutation();

  const bodyObj = useMemo(() => {
    const companyIdArray = [];
    selectedCompanyList?.length > 0 &&
      selectedCompanyList.map((idItem) => companyIdArray.push(idItem.value));

    const reqObj = {
      companyId: companyIdArray,
      priority: selectedPriority,
      staff: selectedStaff,
      sortBy: selectedSortBy,
      pageNo: currentPage,
    };
    const requestObjectWithFilters = {
      companyId: companyIdArray,
      priority: selectedPriority,
      staff: selectedStaff,
      sortBy: selectedSortBy,
      pageNo: currentPage,
      status: selectedStatus ? [selectedStatus] : [],
      duration: selectedFilterGraph,
      customDate: filterSelectedDate ? filterSelectedDate : undefined,
    };

    return isDashboardFiltersEnabled?.dashboardFilter?.isEnable
      ? companyIdArray?.length
        ? requestObjectWithFilters
        : null
      : companyIdArray?.length
      ? reqObj
      : null;
  }, [
    currentPage,
    selectedSortBy,
    selectedStaff,
    selectedPriority,
    selectedCompanyList,
    selectedStatus,
    selectedFilterGraph,
    filterSelectedDate,
  ]);

  const showFilters = () => {
    setShowFilter(!showFilter);
  };

  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedSortBy("ALL");
    setSelectedPriority("ALL");
    setSelectedStaff("ALL");
    setSelectedStatus("");
    setSelectedFilterGraph(HomeScreenStrings.MONTH);
  };

  useEffect(() => {
    if (state?.selectedFilter.teamsFilter === "SELF" && state.isFromDashboard) {
      setSelectedStaff(state.selectedFilter.teamsFilter);
      setSelectedFilterGraph(state.selectedFilter.durationFilter);
      setShowFilter(true);
      state?.selectedFilter.customFilterSelectedDate &&
        setFilterSelectedDate(state.selectedFilter.customFilterSelectedDate);
    } else if (
      state?.selectedFilter.teamsFilter === "TEAM" &&
      state.isFromDashboard
    ) {
      setSelectedStaff("ALL");
      setSelectedFilterGraph(state.selectedFilter.durationFilter);
      setShowFilter(true);
      state?.selectedFilter.customFilterSelectedDate &&
        setFilterSelectedDate(state.selectedFilter.customFilterSelectedDate);
    } else {
      setSelectedStaff("ALL");
    }
  }, [state]);

  useEffect(() => {
    setTaskListState([]);
    if (selectedCompanyList?.length > 0) {
      setIsLoading(true);
      getReportTaskListApi(bodyObj);

      if (currentPage === 1) {
        setIsGraphLoading(true);
        getGraphDetailsApi(bodyObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("manageTask:selectCompanyMessage"),
      });
      setIsGraphLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyObj]);

  useEffect(() => {
    if (isTaskSuccess) {
      if (taskDataList?.data?.nodes && taskDataList?.success) {
        const { pageInfo, nodes } = taskDataList.data;
        setTaskListState(nodes);
        setLastPageNo(pageInfo.lastPageNo);
      } else {
        taskError?.originalStatus !== 500 &&
          showToast({
            type: "error",
            message: taskDataList?.message,
          });
      }

      setIsLoading(false);
    }

    if (taskError && isTaskError) {
      setIsLoading(false);
      taskError?.originalStatus !== 500 &&
        showToast({
          type: "error",
          message:
            JSON.stringify(taskError?.data?.message) ||
            Translate("common:unknownServerError"),
        });
    }
  }, [taskDataList, isTaskError, taskError, isTaskSuccess]);

  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (exportTaskData?.length > 0) {
      const rows = exportTaskData?.split("\n");
      const rowArray = rows.map((item) => item.trim().split(","));

      setCSVData(rowArray);
    }

    if (exportTaskError) {
      const errorResponse = JSON.parse(exportTaskError?.data);

      exportTaskError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorResponse?.message) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
    }
  }, [exportTaskData, exportTaskError]);

  useEffect(() => {
    if (graphDataList?.data) {
      const { graphData, legendsColor } = graphDataList?.data[0];

      const seriesData = [];
      const labelData = [];
      const colorsData = [];
      const valueData = [];
      const updatedGraphData = [];

      for (const graphItem of graphData) {
        const { color, type, count, y } = graphItem;
        let updatedLabel = type?.toLowerCase();
        let legendColor = "";

        if (type === taskStatusType?.AWAITING_APPROVAL) {
          updatedLabel = Translate("performanceReport:resolved");
          legendColor = legendsColor["resolved"];
        } else {
          legendColor = legendsColor[updatedLabel];
          updatedLabel = Translate(`performanceReport:${updatedLabel}`);
        }

        seriesData.push(count);
        labelData.push(updatedLabel);
        colorsData.push(legendColor);
        valueData.push(y);
        updatedGraphData.push({ type: updatedLabel, count: y });
      }

      setGraphDetails({
        series: isSeriesAllZeros(seriesData) ? [] : seriesData,
        options: {
          labels: labelData,
          colors: colorsData,
          fill: {
            colors: colorsData,
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
          },
          legend: {
            position: isRtl ? "left" : "right",
            horizontalAlign: "center",
            fontSize: "14px",
            fontWeight: 400,
            offsetX: 100,
            offsetY: 0,
            markers: {
              fillColors: colorsData,
            },
            itemMargin: {
              horizontal: 10,
              vertical: 10,
            },
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return "";
              },
              fillSeriesColor: true,
              title: {
                formatter: (seriesName) => {
                  const hoveredItem = updatedGraphData?.find(
                    (item) => item?.type === seriesName
                  );
                  return `${hoveredItem?.type}: ${hoveredItem?.count} %`;
                },
              },
            },
          },
          dataLabels: {
            textAnchor: "middle",
            style: {
              fontSize: "14px",
              fontWeight: "bold",
            },
            formatter: function (val, opts) {
              const labelIndex = opts?.seriesIndex;
              const value = updatedGraphData[labelIndex]?.count;
              return value + "%";
            },
          },
          stroke: {
            width: graphData?.find((item) => Number(item?.y).toFixed(0) === 100)
              ? 0
              : 2,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "right",
                },
              },
            },
          ],
        },
      });
      setIsGraphLoading(false);
    }

    if (graphDataError && isGraphDataError) {
      setIsGraphLoading(false);
      graphDataError?.originalStatus !== 500 &&
        showToast({
          type: "error",
          message: JSON.stringify(graphDataError?.data?.message),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphDataList, isGraphDataError]);

  const customActionCellRender = (params) => {
    return (
      <>
        <div
          className="manageIconColor cursor-pointer"
          onClick={() =>
            navigate(
              params?.row?.moduleType === moduleType.SUBTASK
                ? "/subtask/view"
                : "/taskDetails",
              {
                state: {
                  isReports: true,
                  taskId: params?.row?.taskId,
                  userDetails: params?.row?.userDetails,
                  navigateFrom: "/reports/task",
                },
              }
            )
          }
        >
          <i className={`manageIconColor fa fa-eye `} />
        </div>
      </>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    switch (placeholder) {
      case Translate("taskReport:staffPlaceHolder"): {
        item.target.value !== placeholder
          ? setSelectedStaff(item.target.value)
          : setSelectedStaff("");

        break;
      }
      case Translate("taskReport:sortByPlaceHolder"): {
        item.target.value !== placeholder
          ? setSelectedSortBy(item.target.value)
          : setSelectedSortBy("");

        break;
      }
      case Translate("taskReport:priorityPlaceHolder"): {
        item.target.value !== placeholder
          ? setSelectedPriority(item.target.value)
          : setSelectedPriority("");

        break;
      }
      case Translate("manageTask:statusPlaceHolder"):
        {
          item.target.value !== placeholder
            ? setSelectedStatus(item.target.value)
            : setSelectedStatus("");
        }
        break;
    }
  };

  const handleExportTask = () => {
    exportTaskDetailsApi(bodyObj);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    {userData?.role?.type !==
                      userTypeVendor?.Vendor?.toUpperCase() && (
                      <CompanyHeader setShowModal={setShowModal} />
                    )}
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        {showFilter ? (
                          <div
                            className="row"
                            style={{
                              width: "100%",
                              justifyContent: "space-evenly",
                              gap: "15px",
                            }}
                          >
                            {isDashboardFiltersEnabled?.dashboardFilter
                              ?.isEnable && (
                              <div
                                className="col-lg-2 col-md-2 col-sm-6"
                                style={{ marginTop: "9px" }}
                              >
                                <StyledTypography
                                  sx={{ color: colors.primary_009 }}
                                  component="span"
                                >
                                  {Translate("common:duration")}
                                </StyledTypography>
                                <div className="selectWidth">
                                  <div className="form-group cursor-pointer">
                                    <DashboardDropDown
                                      options={allFilterData}
                                      className={"cursor-pointer px-1"}
                                      placeholder={Translate(
                                        "taskReport:priorityPlaceHolder"
                                      )}
                                      onSelect={(value) => {
                                        if (
                                          value === HomeScreenStrings.CUSTOM
                                        ) {
                                          setShowDatePickerModal(true);
                                        } else {
                                          setSelectedFilterGraph(value);
                                          setValue(
                                            getDayJs(getMoment().format())
                                          );
                                          setFilterSelectedDate();
                                        }
                                      }}
                                      value={selectedFilterGraph}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {isDashboardFiltersEnabled?.dashboardFilter
                              ?.isEnable && (
                              <div
                                className="col-lg-2 col-md-2 col-sm-6"
                                style={{ marginTop: "9px" }}
                              >
                                <StyledTypography
                                  sx={{ color: colors.primary_009 }}
                                  component="span"
                                >
                                  {Translate("manageTask:status")}
                                </StyledTypography>
                                <div className="selectWidth">
                                  <div className="form-group cursor-pointer">
                                    <DropDownField
                                      options={statusOptions}
                                      className={
                                        "form-control textFieldHeight cursor-pointer"
                                      }
                                      placeholder={Translate(
                                        "manageTask:statusPlaceHolder"
                                      )}
                                      onChange={(item) =>
                                        setFilterValue(
                                          item,
                                          Translate(
                                            "manageTask:statusPlaceHolder"
                                          )
                                        )
                                      }
                                      value={selectedStatus}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* <div
                              className={`${
                                !showStaffFilter
                                  ? "col-lg-6 col-md-6"
                                  : "col-lg-2 col-md-2"
                              } col-sm-6`}
                              style={{ marginTop: "9px" }}
                            > */}
                            <div
                              className={`${
                                !showStaffFilter
                                  ? "col-lg-6 col-md-6"
                                  : isDashboardFiltersEnabled?.dashboardFilter
                                      ?.isEnable
                                  ? "col-lg-2 col-md-2"
                                  : "col-lg-4 col-md-4"
                              } col-sm-6`}
                              style={{ marginTop: "9px" }}
                            >
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span"
                              >
                                {Translate("taskReport:priorityLabel")}
                              </StyledTypography>
                              <div className="selectWidth">
                                <div className="form-group cursor-pointer">
                                  <DropDownField
                                    options={priorityOptions}
                                    className={
                                      "form-control textFieldHeight cursor-pointer"
                                    }
                                    placeholder={Translate(
                                      "taskReport:priorityPlaceHolder"
                                    )}
                                    onChange={(item) =>
                                      setFilterValue(
                                        item,
                                        Translate(
                                          "taskReport:priorityPlaceHolder"
                                        )
                                      )
                                    }
                                    value={selectedPriority}
                                  />
                                </div>
                              </div>
                            </div>
                            {showStaffFilter && (
                              <div
                                // className="col-lg-2 col-md-2 col-sm-6"
                                className={`${
                                  isDashboardFiltersEnabled?.dashboardFilter
                                    ?.isEnable
                                    ? "col-lg-2 col-md-2 col-sm-6"
                                    : "col-lg-4 col-md-4 col-sm-6"
                                }`}
                                style={{ marginTop: "9px" }}
                              >
                                <StyledTypography
                                  sx={{ color: colors.primary_009 }}
                                  component="span"
                                >
                                  {Translate("taskReport:staffLabel")}
                                </StyledTypography>
                                <div className="selectWidth">
                                  <div className="form-group cursor-pointer">
                                    <DropDownField
                                      options={staffOption}
                                      className={
                                        "form-control textFieldHeight cursor-pointer"
                                      }
                                      placeholder={Translate(
                                        "taskReport:staffPlaceHolder"
                                      )}
                                      onChange={(item) =>
                                        setFilterValue(
                                          item,
                                          Translate(
                                            "taskReport:staffPlaceHolder"
                                          )
                                        )
                                      }
                                      value={selectedStaff}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              className={`${
                                !showStaffFilter
                                  ? "col-lg-6 col-md-6"
                                  : isDashboardFiltersEnabled?.dashboardFilter
                                      ?.isEnable
                                  ? "col-lg-2 col-md-2"
                                  : "col-lg-3 col-md-3"
                              } col-sm-6`}
                              style={{ marginTop: "9px" }}
                            >
                              <StyledTypography
                                sx={{ color: colors.primary_009 }}
                                component="span"
                              >
                                {Translate("taskReport:sortByLabel")}
                              </StyledTypography>
                              {/* <div className="selectWidth"> */}
                              <div className="form-group cursor-pointer">
                                <DropDownField
                                  options={sortOptions}
                                  className={
                                    "form-control textFieldHeight cursor-pointer"
                                  }
                                  placeholder={Translate(
                                    "taskReport:sortByPlaceHolder"
                                  )}
                                  onChange={(item) =>
                                    setFilterValue(
                                      item,
                                      Translate("taskReport:sortByPlaceHolder")
                                    )
                                  }
                                  value={selectedSortBy}
                                />
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        ) : null}
                        <div
                          className="filterIconBox headerMargin mx-2"
                          onClick={() => {
                            showFilter && clearFilter();
                            showFilters(!showFilter);
                          }}
                        >
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}
                            >
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                        <form className="headerMargin ml-3">
                          <div className="header-action pull-right">
                            <button
                              type="button"
                              className="btn btn-primary saveButton py-2"
                              onClick={handleExportTask}
                              disabled={!taskListState?.length}
                            >
                              {isExportTaskLoading ? (
                                <div className="align-self-center">
                                  <CircularProgress size={22} color="inherit" />
                                </div>
                              ) : (
                                <>
                                  <i className="fe fe-download" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span"
                                  >
                                    {Translate("common:exportLabel")}
                                  </StyledTypography>
                                </>
                              )}
                            </button>

                            {csvData?.length > 0 && (
                              <CSVLink
                                data={csvData}
                                ref={csvLink}
                                filename={`${Translate(
                                  "taskReport:exportTasKFileName"
                                )}_${formatDateYYYYMMDD(
                                  getMoment().toDate()
                                )}.csv`}
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="taskReportGraph mb-40">
                  {isGraphDataLoading || isGraphLoading ? (
                    <Loader isListLoader />
                  ) : graphDetails?.series?.length ? (
                    <Donutchart
                      customOptions={graphDetails?.options}
                      series={graphDetails?.series}
                      height={"300px"}
                    />
                  ) : (
                    <div className="taskReportGraphNoData">
                      <p>{Translate("taskReport:graphDataNotFound")}</p>
                    </div>
                  )}
                </div>

                <ListView
                  columns={getTaskReportColumns(
                    customSrCellRender,
                    customActionCellRender,
                    false
                  )}
                  rows={taskListState}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isTaskLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <DatePickerModal
        value={value}
        setValue={setValue}
        closeDatePickerModal={hideDatePickerModal}
        setFilterSelectedDate={setFilterSelectedDate}
        showDatePickerModal={showDatePickerModal}
        handleApply={() => setSelectedFilterGraph(HomeScreenStrings.CUSTOM)}
      />

      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};

export default TaskReports;
