import { useFormikContext } from "formik";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import { colors } from "../../../../common/Theme/Colors";
import { userTypes } from "../../../../common/users/userTypes";
import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../../common/Utils/ApiConstants";
import {
  convertToB64,
  getAddressError,
  getMoment,
  handleEventKeySubmit,
} from "../../../../common/Utils/commonFunction";
import {
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import { CustomUserItem } from "../../../common/CustomCellRender";
import PageHeader from "../../../common/pageHeader";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../../FormikForm/component/FormikDropDownPicker";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import RadioButtons from "../../../RadioButtons";
import Loader from "../../Owner/LoadingComp";
import { InitialValues, radioOptions } from "./constants";

const ProcurementOfficerForm = ({
  isAdd,
  isView,
  isEdit,
  isLoading,
  poData,
  formikRef,
  values,
  setFieldValue,
  handleSubmit,
  setAddMoreProcurementOfficer,
  setReportTo,
  filesAdded,
  setFilesAdded,
  images,
  setImages,
  deleteProcurementOfficer,
  isRating,
  isRedZone,
  setIsRedZone,
  showAlert,
  setShowAlert,
  selectedStaff,
  setSelectedStaff,
  error,
  isError,
}) => {
  const navigate = useNavigate();
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );
  const { setFieldError } = useFormikContext();
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");
  const [reportToObj, setReportToObj] = useState({});
  const [staffOptions, setStaffOptions] = useState([
    {
      label: "Procurement & Finance officer",
      value: "PO",
    },
  ]);
  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  useEffect(() => {
    isAdd
      ? getCompanyList({ isFilterEnabled: true, filterRole: userTypes.Po })
      : getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];
      const companyIds = [];
      companyResponse.map((item) => {
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        });
        companyIds.push(item?._id);
      });
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);
  useEffect(() => {
    if (isError && error) {
      const poError = error?.data;
      if (!poError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        poError?.error?.length
          ? poError.error.map((errorItem) => {
              const isPresent = getAddressError(errorItem.param, setFieldError);
              !isPresent && setFieldError(errorItem.param, errorItem.msg);
            })
          : showToast({
              message:
                poError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  useEffect(() => {
    if (userData?._id && (isAdd || isEdit)) {
      const { _id, name, role, profileUrl, login, reportToUser } = userData;
      if (
        role?.type === userTypes.persoalAssistant &&
        reportToUser?.role?.type === userTypes.Owner.toUpperCase()
      ) {
        const reportTo = {
          id: reportToUser._id,
          name: reportToUser?.name,
          role: reportToUser?.role?.type,
          profileUrl: reportToUser?.profileUrl,
        };
        setReportTo && setReportTo(reportTo);
        setReportToObj({
          value: reportToUser?.name,
          label: (
            <CustomUserItem
              userName={reportToUser?.name}
              title={
                reportToUser?.role?.type
                  ? `${reportToUser?.name} | ${reportToUser?.role?.type}`
                  : reportToUser?.name
              }
              profileUrl={reportToUser?.profileUrl}
              email={reportToUser?.login?.email}
            />
          ),
        });
      } else {
        const reportTo = {
          id: _id,
          name,
          role: role?.type,
          profileUrl,
        };
        setReportTo && setReportTo(reportTo);
        setReportToObj({
          value: name,
          label: (
            <CustomUserItem
              userName={name}
              title={role?.type ? `${name} | ${role?.type}` : name}
              profileUrl={profileUrl}
              email={login?.email}
            />
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (poData && (isEdit || isView)) {
      const { name, role, email, profileUrl } = poData?.reportTo;
      if (isEdit && (!email || !role)) {
        const reportToRole =
          role?.type === userTypes.persoalAssistant &&
          userData?.reportToUser?.role?.type === userTypes.Owner.toUpperCase()
            ? userData?.reportToUser?.role?.type
            : userData?.role?.type;
        setReportTo &&
          setReportTo({
            ...poData?.reportTo,
            role: reportToRole,
          });
      }
      if (
        poData?.profileImageDetails &&
        Object.keys(poData?.profileImageDetails).includes("url")
      ) {
        setFilesAdded([poData?.profileImageDetails]);
        setImages([poData?.profileImageDetails?.url]);
      }
      const company = poData?.company?.map((item) => item?._id);
      setFieldValue("name", poData?.name);
      setFieldValue("staff", "PO");
      setFieldValue("companyId", company[0]);
      setFieldValue("dob", poData?.dob);
      setFieldValue("designation", poData?.designation);
      setFieldValue("department", poData?.department);
      setFieldValue("email", poData?.login?.email);
      setFieldValue("reportTo", poData?.reportTo?.id);
      setFieldValue("gender", poData?.gender);
      setFieldValue(
        "mobile",
        `${poData?.login?.countryCode}-${poData?.login?.mobile}`
      );
      setFieldValue("mobileShortCode", poData?.login.mobileShortCode);
      setFieldValue("countryCode", poData?.login.countryCode);
      if (poData?.alternateMobile) {
        setFieldValue(
          "alternateMobile",
          `${poData?.alternateMobileCountryCode}-${poData?.alternateMobile}`
        );
      }
      if (poData?.hrMobile) {
        setFieldValue(
          "hrMobile",
          `${poData?.hrMobileCountryCode}-${poData?.hrMobile}`
        );
      }
      setFieldValue(
        "hrMobileShortCode",
        poData?.hrMobileShortCode ? poData?.hrMobileShortCode : "JO"
      );
      setFieldValue(
        "hrMobileCountryCode",
        poData?.hrMobileCountryCode ? poData?.hrMobileCountryCode : "962"
      );
      setFieldValue(
        "alternateMobileCountryCode",
        poData?.alternateMobileCountryCode
          ? poData?.alternateMobileCountryCode
          : jordanCountryCode
      );
      setFieldValue(
        "alternateMobileShortCode",
        poData?.alternateMobileShortCode
          ? poData?.alternateMobileShortCode
          : jordanCountryShortCode
      );
      setFieldValue("companyNumber", poData?.companyNumber);
      setFieldValue("companyExtension", poData?.companyExtension);
      setFieldValue(poData?.gender == "MALE" ? "Male" : "Female", true);
      setFieldValue("resAddress", poData?.residenceAddress?.address);
      setFieldValue("resFlat", poData?.residenceAddress.flat);
      setFieldValue("resCountry", poData?.residenceAddress?.country);
      setFieldValue("resState", poData?.residenceAddress?.state);
      setFieldValue("resCity", poData?.residenceAddress?.city);
      setFieldValue("resZipCode", poData?.residenceAddress?.zipCode);
      setFieldValue("resLat", poData?.residenceAddress.latlong.latitude);
      setFieldValue("resLng", poData?.residenceAddress.latlong.longitude);
      setFieldValue("workAddress", poData?.workAddress?.address);
      setFieldValue("workFlat", poData?.workAddress.flat);
      setFieldValue("workCountry", poData?.workAddress?.country);
      setFieldValue("workState", poData?.workAddress?.state);
      setFieldValue("workCity", poData?.workAddress?.city);
      setFieldValue("workZipCode", poData?.workAddress?.zipCode);
      formikRef.current.setFieldValue(
        "workLat",
        poData?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "workLng",
        poData?.workAddress.latlong.longitude
      );
      setReportToObj({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
  }, [poData]);
  const currentLocationData = (places, addressType) => {
    if (addressType) {
      setFieldValue("resAddress", places.formatted_address);
      setFieldValue("resLat", places.geometry.location.lat());
      setFieldValue("resLng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          setFieldValue("resZipCode", ele.long_name);
        } else {
          setFieldValue("resZipCode", "");
        }
      });
    } else {
      setFieldValue("workAddress", places.formatted_address);
      setFieldValue("workLat", places.geometry.location.lat());
      setFieldValue("workLng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          setFieldValue("workZipCode", ele.long_name);
        } else {
          setFieldValue("workZipCode", "");
        }
      });
    }
  };

  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);
    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      setFieldValue("dob", new Date(value));
      InitialValues.dob = new Date(value);
    } else {
      showToast({
        message: Translate("addPersonalAssistant:ageAlert"),
        type: "error",
      });
      setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const { type } = file;
    const fileTypes = ["image/png", "image/png", "image/jpeg"];

    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;

    if (url) file.preview = url;

    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = () => {
    setFilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancel = () => setShowAlert(false);

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const getPageTitle = () => {
    if (isView) {
      return Translate("addpo:viewProcurementOfficerHeading");
    }

    if (isEdit) {
      return Translate("addpo:editProcurementOfficerHeading");
    }

    return Translate("addpo:addProcurementOfficerHeading");
  };

  const navigateToList = () => {
    setFieldValue("companyId", "");
    return navigate("/procurementOfficers");
  };

  useEffect(() => {
    return () => {
      InitialValues.dob = "";
      InitialValues.companyId = "";
    };
  }, []);

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={getPageTitle()}
            onClick={() => {
              isEdit
                ? navigate("/procurementOfficers/view", { state: poData._id })
                : navigateToList();
            }}
            RightContainer={() => (
              <>
                {isView && poData?.isEditable && (
                  <div className="card-options">
                    <i
                      className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                      onClick={() =>
                        navigate("/procurementOfficers/edit", {
                          state: poData,
                        })
                      }
                      data-toggle="tooltip"
                      title={Translate("common:editToolTip")}
                    ></i>
                  </div>
                )}

                {isEdit && poData?.isDeleteable && (
                  <div className="card-options">
                    <i
                      className="fe fe-trash-2 cursor-pointer"
                      onClick={() => setShowAlert(true)}
                      data-toggle="tooltip"
                      title={Translate("common:deleteToolTip")}
                    ></i>
                  </div>
                )}
              </>
            )}
          />
          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
          >
            <div className="row imageDisplay mb-2">
              <div className="form-group col-md-4 col-sm-8">
                <Persona
                  isProfile={isAdd}
                  name={poData?.name}
                  isBase64={base64Img ? true : false}
                  image={
                    filesAdded?.length > 0 && images?.length > 0
                      ? images[0]?.includes(VALIDATION_BUCKETURL)
                        ? images[0]
                        : base64Img
                      : poData
                      ? poData?.profileImageDetails?.url
                      : ""
                  }
                  size={96}
                />
                {!isView && (
                  <Dropzone
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    fileLength={filesAdded.length}
                    maxFileSize={50 * 1000000}
                    className="staffProfileIcon"
                    isProfile
                    deleteAlert={() => {
                      setShowRemoveProfileAlert(true);
                    }}
                    isShowCameraDeleteIcon={
                      (isAdd || isEdit) && filesAdded?.length
                    }
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={allCompanyData}
                  name="companyId"
                  label={Translate("addPersonalAssistant:selectCompany")}
                  placeholder={Translate(
                    "addPersonalAssistant:dropdownPlaceholder"
                  )}
                  disabled={!isAdd}
                  required={isAdd || isEdit}
                  autoFocus={isAdd}
                  noDataMsg={Translate("procurementRequest:noCompanyMessage")}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={staffOptions}
                  value={selectedStaff}
                  name="staff"
                  label={Translate("addHrOfficer:staff")}
                  placeholder={Translate("addHrOfficer:staffplaceholder")}
                  onSelect={(value) => {
                    setSelectedStaff(value);
                  }}
                  required={isAdd || isEdit}
                  disabled
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("addpo:name")}
                  placeholder={Translate("addpo:name")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDatePicker
                  label={Translate("addPersonalAssistant:dob")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0 "
                  format="yyyy-MM-dd"
                  name="dob"
                  value={values.dob}
                  onChange={onDobChange}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="designation"
                  label={Translate("addPersonalAssistant:designation")}
                  placeholder={Translate("addPersonalAssistant:designation")}
                  autoComplete={"off"}
                  maxLength={validations?.designationLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="department"
                  label={Translate("addPersonalAssistant:department")}
                  placeholder={Translate("addPersonalAssistant:department")}
                  autoComplete={"off"}
                  maxLength={validations?.departmentLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikSearchableDropDown
                  selected={reportToObj}
                  options={[]}
                  placeholder={Translate("addPersonalAssistant:reportTo")}
                  label={Translate("addPersonalAssistant:reportTo")}
                  name="reportTo"
                  disabled
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="email"
                  label={Translate("addPersonalAssistant:email")}
                  placeholder={Translate(
                    "addPersonalAssistant:emailPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.email.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12" id="paContact">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate("addPersonalAssistant:contactNumber")}
                    name="mobile"
                    value={values.mobile}
                    placeholder={Translate(
                      "addPersonalAssistant:contactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("mobile", value);
                      setFieldValue(
                        "mobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("countryCode", country?.dialCode);
                    }}
                    autoComplete={"off"}
                    required={isAdd || isEdit}
                    // disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="mobile"
                    value={poData?.login?.mobileWithCountryCode}
                    label={Translate("addPersonalAssistant:contactNumber")}
                    placeholder={Translate(
                      "addPersonalAssistant:contactNumberPlaceHolder"
                    )}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12" id="paContact">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate(
                      "addPersonalAssistant:alternateContactNumber"
                    )}
                    name="alternateMobile"
                    value={values.alternateMobile}
                    placeholder={Translate(
                      "addPersonalAssistant:alternateContactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("alternateMobile", value);
                      setFieldValue(
                        "alternateMobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue(
                        "alternateMobileCountryCode",
                        country?.dialCode
                      );
                    }}
                    autoComplete={"off"}
                    disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="alternateMobile"
                    value={
                      poData?.alternateMobileWithCountryCode
                        ? poData?.alternateMobileWithCountryCode
                        : "NA"
                    }
                    label={Translate(
                      "addPersonalAssistant:alternateContactNumber"
                    )}
                    placeholder={Translate(
                      "addPersonalAssistant:alternateContactNumberPlaceHolder"
                    )}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>

              <div className="form-group col-md-4 col-sm-12">
                <FormikPhoneInputWithExtField
                  name="companyNumber"
                  extName="companyExtension"
                  label={Translate(
                    "addPersonalAssistant:companyExtensionNumber"
                  )}
                  placeholder={Translate(
                    "addPersonalAssistant:extensionNumberPlaceholder"
                  )}
                  extPlaceholder={Translate(
                    "addPersonalAssistant:companyPhoneExtensionPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.telephoneLength.MAX}
                  extMaxLength={validations?.companyExtension.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    placeholder={Translate("addOwner:hrContactPlaceholder")}
                    label={Translate("addOwner:hrContact")}
                    name="hrMobile"
                    value={values.hrMobile}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("hrMobile", value);
                      setFieldValue(
                        "hrMobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("hrMobileCountryCode", country?.dialCode);
                    }}
                    autoComplete={"off"}
                    disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="hrMobile"
                    value={
                      poData?.hrMobileWithCountryCode
                        ? poData?.hrMobileWithCountryCode
                        : "NA"
                    }
                    placeholder={Translate("addOwner:hrContactPlaceholder")}
                    label={Translate("addOwner:hrContact")}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    style={{ fontWeight: 500 }}
                    variant="subtitle1"
                  >
                    {Translate("addPersonalAssistant:address")}
                    {(isAdd || isEdit) && (
                      <span style={{ color: "red" }}>{" *"}</span>
                    )}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="resAddress"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_1"
                  )}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, true);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="resFlat"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_2"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCountry"
                  placeholder={Translate(
                    "addPersonalAssistant:countryPlaceholder"
                  )}
                  disabled={isView}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resState"
                  placeholder={Translate(
                    "addPersonalAssistant:statePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCity"
                  placeholder={Translate(
                    "addPersonalAssistant:cityPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resZipCode"
                  placeholder={Translate(
                    "addPersonalAssistant:zipcodePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    style={{ fontWeight: 500 }}
                    variant="subtitle1"
                  >
                    {Translate("addPersonalAssistant:workAddress")}
                    {(isAdd || isEdit) && (
                      <span style={{ color: "red" }}>{" *"}</span>
                    )}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="workAddress"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_3"
                  )}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, false);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="workFlat"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_4"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workCountry"
                  disabled={isView}
                  placeholder={Translate(
                    "addPersonalAssistant:countryPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workState"
                  disabled={isView}
                  placeholder={Translate(
                    "addPersonalAssistant:statePlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workCity"
                  placeholder={Translate(
                    "addPersonalAssistant:cityPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workZipCode"
                  placeholder={Translate(
                    "addPersonalAssistant:zipcodePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                {(isAdd || isEdit || (isView && poData?.gender)) && (
                  <RadioButtons
                    radioOptions={radioOptions}
                    defaultChecked={poData?.gender === "FEMALE" ? 1 : 0}
                    label={Translate("addPersonalAssistant:gender")}
                    onPress={(value) => {
                      setFieldValue("gender", value.target.value);
                    }}
                    disabled={isView}
                    required={isAdd || isEdit}
                  />
                )}
              </div>
            </div>
            {isView && isRating && (
              <div>
                <div>
                  <span style={{ color: colors.primary_003, fontWeight: 500 }}>
                    {Translate("common:rate")}
                  </span>
                </div>
                <div className="row">
                  <h6
                    className="nav-link"
                    style={{
                      color: isRedZone ? colors.red : colors.green,
                      paddingBottom: 10,
                    }}
                  >
                    {isRedZone
                      ? Translate("common:redZone")
                      : Translate("common:greenZone")}
                  </h6>
                  <Switch
                    onChange={() => {
                      setIsRedZone((prev) => !prev);
                    }}
                    checked={isRedZone}
                    height={25}
                    width={50}
                    onHandleColor={colors.primary}
                    onColor={colors.primary_002}
                  />
                </div>
              </div>
            )}
            {isView && (poData?.isRateable || poData?.isPerformanceAccess) && (
              <div className="card-footer text-right">
                {poData?.isPerformanceAccess && (
                  <button
                    type="submit"
                    className={`btn btn-primary saveButton ${
                      isRating && "staffPerformanceBtn"
                    }`}
                    onClick={() =>
                      navigate("/staff/performance", {
                        state: { userData: poData },
                      })
                    }
                  >
                    {Translate("common:performance")}
                  </button>
                )}
                {poData?.isRateable && (
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {Translate("addPersonalAssistant:save")}
                  </button>
                )}
              </div>
            )}

            {(isAdd || isEdit) && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => {
                    handleSubmit();
                    isAdd && setAddMoreProcurementOfficer(false);
                  }}
                >
                  {Translate("addPersonalAssistant:save")}
                </button>
                {isAdd && (
                  <button
                    type="submit"
                    className="btn btn-white addButton"
                    onClick={() => {
                      handleSubmit();
                      isAdd && setAddMoreProcurementOfficer(true);
                    }}
                  >
                    {Translate("addPersonalAssistant:addMore")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isCompanyLoading} />
      <AlertPermission
        show={showAlert}
        subTitle={Translate("addpo:deleteAlert")}
        onOk={deleteProcurementOfficer}
        onCanel={onCancel}
      />
      <AlertPermission
        show={showRemoveProfileAlert}
        subTitle={Translate("common:deleteProfileAlert")}
        onOk={removeFile}
        onCanel={onCancelProfile}
      />
    </>
  );
};

export default memo(ProcurementOfficerForm);
